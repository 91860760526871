export const companyDetail = {
  email: "forensicinvestigationagency21@gmail.com",
  contact: "+91 94079473 79/89",
  address: "Bilaspur (C.G.)",
  twitter: "https://twitter.com/fiabilaspurcg",
  facebook: "https://www.facebook.com/fiabilaspurcg",
  instagram: "https://www.instagram.com/fiabilaspurcg/",
};

export const baseUrl = 'https://fiabilaspurcgcom-default-rtdb.firebaseio.com/'
